import head1 from "../../../images/team_1.jpg"
import head2 from "../../../images/team_2.jpg"
import head3 from "../../../images/team_3.jpg"
import head4 from "../../../images/team_4.jpg"
import head5 from "../../../images/team_5.jpg"
import head6 from "../../../images/team_6.jpg"
import head7 from "../../../images/team_7.jpg"
import head8 from "../../../images/team_8.jpg"

export const TeamModel1 = {
    head: head1,
    name: "Beatrice O'Brien",
    role: "Founder Chairman & CEO",
    text1: "From the High Courts of England and Australia to top Wall Street firms, Founder, Chairman and CEO Beatrice O’Brien brings more than 25 years of legal industry experience to the table. CEO O’Brien understands the complex nexus of global business and law that only an international lens can provide. CEO O’Brien was the first Fiji Indian attorney working at Wall Street law firm Cadwalader Wickersham & Taft.",
    text2: "Born in Suva in the Fiji Islands, CEO O’Brien was educated in Australia and became an (attorney) solicitor and barrister in the New South Wales Supreme Court. A facile legal mind has allowed her to develop a diverse depth of practice in litigation, corporate real estate, banking and tax law having worked in the UK and the US on some of the largest reorganization and restructuring cases in corporate history.",
    text3: "Ever the innovator, CEO O’Brien founded and leads Attorney Placements International (API), a global legal search, recruitment and consulting firm that facilitates law firm mergers and acquisitions moving entire legal practice groups to new firms and placing partners and associates with high-profile international clients. As an ardent educator, she has created revolutionary programs such as the New York Bar Review Quality Program which assists international attorneys in qualifying their unique skills and experience to practice in the US market.",
    text4: "Now, Beatrice O’Brien, in founding and leading JusGlobal, is introducing technology innovations backed by an assembly of diverse professionals with the highest credentialing to offer unprecedented insight, guidance and security across the legal, pharmaceutical, health care and military clinical trials space. CEO O’Brien speaks fluent Hindi.",
}

export const TeamModel2 = {
    head: head2,
    name: "Kyle Hoedebecke",
    role: "Chief Medical Director",
    text1: "With nearly 20 years of leadership experience in research, clinical practice, academic, and military settings - Dr. Kyle Hoedebecke has led organizations in key metrics such as quality, safety, patient satisfaction, cost savings, and access to care. As a result, his innovative processes and resultant outcomes have been recognized by key leaders across the world.",
    text2: "Dr. Hoedebecke is a Fellow of the American Academy of Family Physicians and has over 80 publications across five continents in eight languages.  His expertise includes technology, leadership, and improved systemic efficiencies as applied to all aspects of healthcare. Other ancillary work includes serving on the editorial boards of three peer-reviewed journals as well as teaching as an Assistant Professor. Beyond being board certified in Family Medicine, his additional studies include four master degrees in business, public administration, leadership, and telemedicine.",
    text3: "",
    text4: "",
}

export const TeamModel3 = {
    head: head3,
    name: "Oliver Ramsbottom",
    role: "Advisory Board Member",
    text1: "A Harvard and Oxford graduate, Mr. Ramsbottom is a partner at global management consulting firm McKinsey & Company. He has extensive international business experience, having worked in London, Mainland China and Tokyo for over 25 years. Mr. Ramsbottom’s expertise includes strategy, corporate finance and business development for global enterprises and financial institutions, including private equity firms and sovereign wealth funds.",
    text2: "Mr. Ramsbottom speaks fluent Mandarin.",
    text3: "",
    text4: "",
}

export const TeamModel4 = {
    head: head4,
    name: "Duane Lakings",
    role: "Chief Scientific Officer",
    text1: "Duane Lakings delivers a broad array of expertise in various primary areas of drug discovery and development to the JusGlobal enterprise. Dr. Lakings is experienced across nonclinical pharmacology, pharmacokinetics and toxicology as well as clinical safety, efficacy, chemistry, manufacturing and control of drug substances and proposed drug products and regulatory affairs. He assists pharmaceutical and biotechnology companies in evaluating results from their drug discovery and development programs, including document preparation, protocol design and implementation across multiple disciplines, as well as further evaluation of drug candidates to support regulatory submission.",
    text2: "Dr. Lakings has served as president of Texas-based Drug Safety Evaluation and was director of drug safety evaluations at Regeneron Pharmaceuticals in Tarrytown, New York as well as a senior research investigator II at BMS-PRI (Princeton, NJ). He has been a research scientist at The Upjohn Company in Michigan and he has conducted research for the National Cancer Institute and the University of Missouri-Columbia.",
    text3: "Dr. Lakings has extensive publishing credits. He has authored or co-authored 44 refereed papers and 150 company-specific papers, nine book chapters on drug development, 26 abstracts for oral/poster presentation, eight commissioned drug candidate evaluation reviews and a 300-page report on biological contract research organizations (CROs).",
    text4: "",
}

export const TeamModel5 = {
    head: head5,
    name: "Eyal Ron",
    role: "Sr Managing Director, Product Development",
    text1: "Dr. Eyal Ron adds value for JusGlobal Pharma clients in the pharmaceutical, biotechnology and medical device space by accelerating the timeline toward the value inflection point. Dr. Ron delivers an entrepreneurial thought process coupled with science-based evaluation to enhance the overarching enterprise value regarding product candidates, without creating a need for costly infrastructure to foster growth.",
    text2: "With three decades of experience in the development of drugs, biomaterials, devices and drug delivery systems, Dr. Ron has become known for combining science with sound business strategy, making him an ideally qualified expert on the JusGlobal team. He developed and implemented the scientific, regulatory and clinical strategy for several companies. Dr. Ron offers extensive depth in the total therapeutic development process including project management, clinical trials, regulatory compliance and approval, drug delivery systems, quality control and scale-up. Dr. Ron has authored more than 100 papers, book chapters and abstracts. He is also the inventor of 50 patents, principally in drug formulations, drug delivery, tissue engineering and biomaterials.",
    text3: "He has been a founder or principal formulator of biotech companies, bringing seven new medications to market. He has held the position of COO, CTO and CSO, director of pharmaceutical development and principal investigator at several pharmaceutical and biotech firms.",
    text4: "Dr. Ron received a PhD from Brandeis University and has completed post graduate study at Massachusetts Institute of Technology. He earned a BS at Tel Aviv University.",
}

export const TeamModel6 = {
    head: head6,
    name: "Evan Siegel, Ph.D.",
    role: "Chief Regulatory Officer",
    text1: "Dr. Evan Siegel adds value for Jus- Pharma clients in the pharmaceutical, biotechnology and medical device space in the Regulatory Affairs and Product Development.Dr. Siegel served as Chief Executive Officer of OXO Chemie Inc. from 1997 to 1999. Before he joined OXO Chemie, he was the Vice President, Regulatory Affairs and Bioethics for Medical Science Systems, Inc. Prior to that he was Director of Regulatory Consulting Services and Principal Regulatory Scientist for Quintiles, Inc., in Research Triangle Park, North Carolina. He has served in regulatory affairs and executive positions in the pharmaceutical (Astra, Syntex, and OXO Chemie), Genetics Testing Industries (Medical Science Systems); and trade association environments.",
    text2: "Dr. Siegel has also held positions as a Toxicology Reviewer at the US Food and Drug Administration and California Department of Health Services and, at the latter, was Chief of Special Services.",
    text3: "He attended Bucknall University where he received a Bachelor’s of Science degree in Physics; Rutgers University, where he earned a Masters of Science degree in Radiological Health/Health Physics; the Waksman Institute of Microbiology where he earned both his Masters and Doctor of Philosophy degrees in Virology and Molecular Biology, and was awarded Postdoctoral Fellowships in Medical Genetics and Genetics Counselling at the University of North Carolina at Chapel Hill.",
    text4: "Dr. Siegel is an Adjunct Professor at the University of Queensland and the Centre for the Study of Preclinical Drug Development, Brisbane, Australia, and a member of the California Lieutenant Governor’s Biotechnology Advisory Group to the California Council for Economic Development.",
}

export const TeamModel7 = {
    head: head7,
    name: "Scott G Grubin",
    role: "Senior Advisor",
    text1: "An attorney with a unique multidisciplinary background, and a career on Wall Street, brings tremendous value to our team. Mr. Grubin has been recognized as a Super Lawyer.",
    text2: "",
    text3: "",
    text4: "",
}

export const TeamModel8 = {
    head: head8,
    name: "Patricia Blaine",
    role: "Senior Medical Writer",
    text1: "Patricia Blaine is a registered respiratory therapist, university instructor and accomplished medical writer. She has served as a consultant in clinical trial protocols for leading pharmaceutical companies such as Eisai, Daiichi Sankyo, Forest Laboratories and Schering-Plough.",
    text2: "Ms. Blaine has been a senior director of medical writing and a staff medical writer for Oxford Research, Corning-Besselaar and Schering-Plough. She has been director of clinical education at the University of Medicine and Dentistry of New Jersey (UMDNJ). Ms. Blaine provided medical writing for a poster presented at a psychiatric conference focusing on a Phase IV multi-site investigatory study on carmbamazepine.",
    text3: "Patricia Blaine earned a Master’s degree in Education with a concentration in Allied Health Education from Rutgers University and a Bachelor of Arts in Psychology and English from Seton Hill University (Pennsylvania).",
    text4: "",
}