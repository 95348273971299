import React from "react"
import Layout from "../components/layout"
import { Link, ModalRoutingContext } from 'gatsby-plugin-modal-routing'

const ConditionalLayout = ({ children, ...rest }) => (
    <ModalRoutingContext.Consumer>
        {({ modal, closeTo }) => (
            modal ? (
            <React.Fragment>
                <Link to={closeTo} className="close_st">
                    ×
                </Link>
                {children}
            </React.Fragment>
            ) : (
            <Layout { ...rest }>
                <div className="addition_pad">
                    {children}
                </div>
            </Layout>
            )
        )}
    </ModalRoutingContext.Consumer>
)

export default ConditionalLayout
