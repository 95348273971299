import React from "react"
import { Container, Row, Col, Image } from 'react-bootstrap'

const TeamDetailModule = props => {
    
    return (
        <div className="section_pad_st1 team_detail_st">
            <Container>
                <Row className="margin_0">
                    <Col
                        className="col-sm-3"
                    >
                        <Image src={props.member.head} alt={props.member.name} className="mb-4 w-100"/>
                        <h4 className="sub_title main_b_color pad_0">{props.member.name}</h4>
                        <p className="main_text main_b_color">{props.member.role}</p>
                    </Col>
                    <Col
                        className="col-sm-9 pad_l_35"
                    >
                        <p className="main_text main_b_color">{props.member.text1}</p>
                        <p className="main_text main_b_color">{props.member.text2}</p>
                        <p className="main_text main_b_color">{props.member.text3}</p>
                        <p className="main_text main_b_color">{props.member.text4}</p>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default TeamDetailModule